/** 验证方式 */
export enum UserValidateMethodEnum {
  email = 'email', // 邮箱验证
  phone = 'phone', // 手机验证
  validator = 'validator', // 验证器验证
  uid = 'uid', // uid
}

/** 表单验证类型 */
export enum UserValidateFormTypeEnum {
  login = 'login', // 登录
  register = 'register', // 注册
  retrieve = 'retrieve', // 重置密码
}

/** 注册方式 */
export enum UserRegisterTypeEnum {
  default = 'default', // 默认
  thirdParty = 'thirdParty', // 第三方
}

/** 平台类型 */
export enum PlatformTypeEnum {
  web = 1, // web
  h5, // h5
  android, // android
  ios, // ios
}

/** GeeTest 操作类型 */
export enum GeeTestOperationTypeEnum {
  register = 1, // 注册
  login, // 登录
  modifyPassword, // 修改密码
  resetSecurity, // 重置安全项
  createApi, // 创建 Api
}

/** 启用状态 */
export enum UserEnabledStateTypeEnum {
  enable = 1, // 已启用
  unEnable = 2, // 未启用
}

/** 选择国家或者区号类型 */
export enum UserSelectAreaTypeEnum {
  phone = 'phone', // 手机
  area = 'area', // 国家
}

/** 账户安全操作类型 */
export enum AccountSecurityOperationTypeEnum {
  bind = 'bind', // 绑定
  modify = 'modify', // 修改
  delete = 'delete', // 删除
}

/** 涨跌色 */
export enum UserUpsAndDownsColorEnum {
  greenUpRedDown = 1, // 绿涨红跌
  redUpGreenDown = 2, // 红涨绿跌
}

/** 货币符号 */
export enum UserCurrencySymbolEnum {
  cny = '¥', // 人民币
  usd = '$', // 美元
}

/** 货币名称 */
export enum UserCurrencyNameEnum {
  '¥' = 'CNY', // 人民币
  '$' = 'USD', // 美元
}

/** 用户实名认证状态类型 */
export enum UserAuthenticationStatusTypeEnum {
  notCertified = 0, // 未认证
  underReview, // 审核中
  examinationPassed, // 审核通过
  notApprovedCertified, // 不适用
  AuditNotPassed, // 审核不通过
}

/** 登录方式 */
export enum UserLoginMethod {
  web = 1,
  android,
  api,
  h5,
  ios,
}

/** 验证类型 */
export enum UserVerifyTypeEnum {
  phone = 1,
  email,
  uid,
}

/** 验证码验证类型 */
export enum UserSendValidateCodeBusinessTypeEnum {
  register = 1, // 注册
  login, // 登录
  withdraw, // 提币
  resetPassword, // 重置密码
  modifyEmail, // 修改邮箱前进行通用验证
  closeEmailVerification, // 关闭邮箱前进行通用验证
  modifyNewEmail, // 修改新邮箱号
  bindEmail, // 邮箱号绑定
  modifyPhone, // 修改手机号前进行通用验证
  closePhoneVerification, // 关闭手机号前进行通用验证
  modifyNewPhone, // 修改新手机号码
  bindPhone, // 手机号码绑定
  securityItemApply, // 重置安全项申请表单
  modifyGoogle, // 修改谷歌验证
  closeGoogleVerification, // 关闭谷歌验证
  deletePhoneVerification, // 删除手机验证
  resetSecurityItemApprovals, // 重置安全项审批
  closeBiosafetyAuthentication, // 关闭生物安全认证
  createApi, // 创建 Api
}

/** KYC 认证状态 */
export enum UserKycTypeEnum {
  notCertified = 1, // 未认证
  standardCertification, // 个人标准认证
  advancedCertification, // 个人高级认证
  enterpriseCertification, // 企业认证
}

/** 最大值最小值 */

export enum MinAndMaxTypeEnum {
  min = 1, // 最小值
  max, // 最大值
}
/** 合约 */
export enum UserContractVersionEnum {
  professional = 1, // 专业版
  base, // 基础版
}

export enum UserEnableEnum {
  yes = 'yes',
  no = 'no',
}

export enum UserOpenEnum {
  open = 'open',
  close = 'close',
}

/** 开仓额外保证金模式 */
export enum UserMarginSourceEnum {
  wallet = 'wallet', // 资金账户
  group = 'group', // 合约组剩余额外保证金
}

/** 保证金取回方式 */
export enum UserRetrieveWayEnum {
  auto = 'auto', // 自动取回
  manual = 'manual', // 手动取回
}

/** 自动追加保证金档位 */
export enum GearEnum {
  low = 0.7, // 低档
  middle = 0.85, // 中档
  high = 1, // 高档
}

/** 用户当前开启合约状态 */
export enum UserFuturesTradeStatus {
  open = 1,
  close = 2,
}

export enum ContractPreferencesTermsEnum {
  marginProtection = 'margin_protection', // 价差保护
  contractTeachPro = 'contract_teach_pro', // 合约开通专业版教程
  contractTeachNormal = 'contract_teach_normal', // 合约开通普通版教程
}

export enum UserAgreementEnum {
  unreceiveVerificationCode = 'unreceive_verification_code', // 未收到验证码
  termsService = 'terms_service', // 服务条款
}
