import request, { MarkcoinRequest } from '@/plugins/request'

export const postMemberRegisterEmailRequest: MarkcoinRequest = data => {
  return request({
    path: '/register/email',
    method: 'POST',
    data,
    signature: true,
  })
}

export const postMemberRegisterPhoneRequest: MarkcoinRequest = data => {
  return request({
    path: '/register/mobile',
    method: 'POST',
    data,
    signature: true,
  })
}

export const postMemberRegisterMobileCodeRequest: MarkcoinRequest = data => {
  return request({
    path: '/v1/send/sms',
    method: 'POST',
    data,
  })
}

export const postMemberRegisterEmailCodeRequest: MarkcoinRequest = data => {
  return request({
    path: '/v1/send/email',
    method: 'POST',
    data,
  })
}

export const postRegisterValidMobileRequest: MarkcoinRequest = data => {
  return request({
    path: '/register/valid/mobile',
    method: 'POST',
    data,
  })
}

export const postRegisterValidEmailRequest: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/user/register/valid/email',
    method: 'POST',
    data,
  })
}

export const postLoginMobileApiRequest: MarkcoinRequest = data => {
  return request({
    path: '/login/mobile',
    method: 'POST',
    data,
    signature: true,
  })
}

export const postLoginEmailApiRequest: MarkcoinRequest = data => {
  return request({
    path: '/login/email',
    method: 'POST',
    data,
    signature: true,
  })
}

export const postUserPwdResetRequest: MarkcoinRequest = data => {
  return request({
    path: '/user/pwd/reset',
    method: 'POST',
    data,
    signature: true,
  })
}

// 邮箱验证验证接口
export const postSendEmail: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/send/email',
    method: 'POST',
    data,
  })
}

// *****
// export const postUserRegister: MarkcoinRequest = data => {
//   return request({
//     path: '/v1/vpn/user/register/valid/email',
//     method: 'POST',
//     data,
//   })
// }

// 邮箱验证码校验接口
export const postUserRegister: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/send/checkEmailCode',
    method: 'POST',
    data,
  })
}

// 注册接口
export const postUserRegisterEmail: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/user/register/email',
    method: 'POST',
    data,
    signature: true,
  })
}

// 获取商户配置
export const GetBusinessConfig: MarkcoinRequest = params => {
  return request({
    path: '/v1/vpn/getBusinessConfig',
    method: 'get',
    params,
  })
}

// 邮箱登陆接口
export const postUserLoginEmail: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/user/login/email',
    method: 'POST',
    data,
    signature: true,
  })
}

// 手机号登陆
export const postUserLoginMobile: MarkcoinRequest = data => {
  return request({
    path: '/v1/vpn/user/login/mobile',
    method: 'POST',
    data,
    signature: true,
  })
}

// 用户信息
export const getUserInfoVpn = params => {
  return request({
    path: '/v1/vpn/user/getUserInfo',
    method: 'GET',
    params,
  })
}

// 忘记密码
export const getUserPwdResetVpn = data => {
  return request({
    path: '/v1/vpn/user/pwd/reset',
    method: 'POST',
    data,
    signature: true,
  })
}

// 忘记密码邮箱验证码
export const getUsersendEmailVpn = data => {
  return request({
    path: '/v1/vpn/send/email',
    method: 'POST',
    data,
  })
}

// 忘记密码手机验证码
export const getUserSendSmsVpn = data => {
  return request({
    path: '/v1/vpn/send/sms',
    method: 'POST',
    data,
  })
}

// 手机号是否重复注册
export const postRegisterMobile = data => {
  return request({
    path: '/v1/vpn/user/register/valid/mobile',
    method: 'POST',
    data,
  })
}

// 校验手机注册验证码
export const postSendCheckSmsCode = data => {
  return request({
    path: '/v1/vpn/send/checkSmsCode',
    method: 'POST',
    data,
  })
}

// 手机号注册
export const postRegisterMobileVpn = data => {
  return request({
    path: '/v1/vpn/user/register/mobile',
    method: 'POST',
    data,
    signature: true,
  })
}

// 修改密码
export const postUserChangePassword = data => {
  return request({
    path: '/v1/vpn/user/changePassword',
    method: 'POST',
    data,
    signature: true,
  })
}

// 退出登陆
export const postUserLogoutOut = data => {
  return request({
    path: '/v1/vpn/user/logout',
    method: 'POST',
    data,
    signature: true,
  })
}

// 注销用户
export const postUserlogoutUser = data => {
  return request({
    path: '/v1/vpn/user/logoutUser',
    method: 'POST',
    data,
  })
}

/**
 * 注销用户
 * https://yapi.nbttfc365.com/project/97/interface/api/20222
 */
export const logoutUser = data => {
  return request({
    path: `/v1/vpn/user/logoutUser`,
    method: 'POST',
    data,
    signature: true,
  })
}
